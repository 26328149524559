<template>
  <div class="home" v-if="$store.getters.isContentLoaded">
    <KeyVisual :title="page.MainPageKeyVisual.title"
               :text="page.MainPageKeyVisual.description.split('\n\n')"
               :kv="kvImg" />
    <PageMainCards />
    <PageMainBottom />
  </div>

</template>

<script>


import KeyVisual from "../components/KeyVisual/KeyVisual";
import PageMainCards from "../components/page_main/PageMainCards/PageMainCards";
import PageMainBottom from "../components/page_main/PageMainBottom/PageMainBottom";
export default {
  name: 'HomeView',
  data() {
    return {
      apiPage: 'main-page',
      isVersionSet: false,
      kv: {
        img: {
          url: 'page_main/kv.png',
          width: '59.4rem',
          paddingTop: '10rem',
        }
      }
    }
  },
  computed: {
    page() {
      return this.$store.getters.pages[this.apiPage]
    },
    kvImg() {
      let obj = Object.assign(this.kv.img)
      const staticUrl = this.$store.getters.staticUrl
      if (this.page.MainPageKeyVisual.image.data) {
        obj.url = `${staticUrl}${this.page.MainPageKeyVisual.image.data.attributes.url}`
        obj.isUGC = true
      }
      return obj
    }
  },
  methods: {
    getPageData() {
      const data = {
        search: 'MainPageKeyVisual.image,MottoBgImageLeft,MottoBgImageRight',
        apiPage: this.apiPage
      }
      this.$store.dispatch('getPage', data)
    }
  },
  async mounted() {
    this.getPageData()
  },

  components: {
    PageMainBottom,
    PageMainCards,
    KeyVisual
  }
}
</script>

<style scoped>

</style>
