import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home2',
    component: HomeView
  },
  {
    path: '/mission',
    name: 'mission',
    component: () => import('../views/MissionView.vue')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/TeamView.vue')
  },
  {
    path: '/value',
    name: 'value',
    component: () => import('../views/ValueView.vue')
  },
  {
    path: '/charity',
    name: 'charity',
    component: () => import('../views/CharityView.vue')
  },
  {
    path: '/docs',
    name: 'docs',
    component: () => import('../views/DocsView.vue')
  },
  {
    path: '/actual',
    name: 'actual',
    component: () => import('../views/ActualView.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/SupportView.vue')
  },
  {
    path: '/office',
    name: 'office',
    component: () => import('../views/OfficeView.vue')
  },
  {
    path: '/merch',
    name: 'merch',
    component: () => import('../views/MerchView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/TestView.vue')
  },
  {
    path: '/page/:id',
    name: 'page',
    component: () => import('../views/ExtraPageView.vue')
  },
  {
    path: '/section/:id',
    name: 'section',
    component: () => import('../views/UserBlocksView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})


export default router
