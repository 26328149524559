<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="footer__df">
        <div class="footer__copy">
          ©{{ currentYear }} Команда ВКонтакте
        </div>
        <div class="footer__back">
          <router-link to="/home">
            Все разделы
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "PageFooter",
    computed: {
      currentYear() {
        return new Date().getFullYear()
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "footer";
</style>
