<template>
  <section class="cards">
    <div class="wrapper">
      <h2 class="cards__title mobile-hidden">
        <vue-simple-markdown
          :source="$options.filters.typograf(page.CardsBlockTitle)">
        </vue-simple-markdown>
      </h2>
      <ul class="cards__list" v-if="$store.getters.isCardsLoaded">
        <li class="card"
            v-for="(card, index) in cards"
            :key="card.id"
            @click="$router.push(card.attributes.shortname)"
            :style="{ 'order': card.attributes.order }">
          <div class="card__img img-w" :class="'card__img--' + (index+1)">
            <img v-if="card.attributes.icon.data"
                 :src="staticUrl + card.attributes.icon.data.attributes.url"
                 alt="">
          </div>
          <p class="card__text">
            <vue-simple-markdown
              :source="$options.filters.typograf(card.attributes.titleMain)">
            </vue-simple-markdown>
          </p>
        </li>
      </ul>
    </div>
  </section>

</template>

<script>
  export default {
    name: "PageMainCards",
    computed: {
      page() {
        return this.$store.getters.pages['main-page']
      },
      staticUrl() {
        return this.$store.getters.staticUrl
      },
      cards() {
        const v = this.$store.getters.version.replace('-', '').toUpperCase()
        return this.$store.getters.cards.filter(item => item.attributes[`isVisibleOnMainpage${v}`])
      }
    },
  }
</script>

<style scoped lang="scss">
  @import './cards.scss';
</style>
