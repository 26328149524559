import { render, staticRenderFns } from "./CheckTeamModal.vue?vue&type=template&id=06bb58d2&scoped=true&"
import script from "./CheckTeamModal.vue?vue&type=script&lang=js&"
export * from "./CheckTeamModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bb58d2",
  null
  
)

export default component.exports