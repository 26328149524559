<template>
  <header class="header">
    <div class="wrapper">
      <div class="header__logo-box">
        <div class="header__logo img-w">
          <a href="/">
            <PageHeaderLogo />
          </a>
        </div>
        <span>/ <router-link to="/home">{{ $store.getters.currentTeam }}</router-link></span>
      </div>

    </div>

  </header>
</template>

<script>
  import PageHeaderLogo from "../svg/PageHeaderLogo";
  import './pageheader.scss'

  export default {
    name: "PageHeader",
    components: {PageHeaderLogo}
  }
</script>

<style>
  .header__logo-box a {
    text-decoration: none;
  }
</style>
