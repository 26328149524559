import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: '',
    isVersionSet: false,
    apiUrl: 'https://vkhandbook.kdagency.ru/api',
    staticUrl: 'https://vkhandbook.kdagency.ru',
    isContentLoaded: false,
    isCardsLoaded: false,
    activeMerchMobileTab: '1',
    extraPages: {},
    userBlocks: {},
    cards: {},
    nav: {},
    teams: [],
    currentTeam: null,
    pages: {
      'main-page': {
        MainPageKeyVisual: {
          title: '',
          description: '',
          image: {
            data: null,
          }
        },
        CardsBlockTitle: '',
        Motto: '',
        MottoBgImageLeft: {
          data: null,
        },
        MottoBgImageRight: {
          data: null,
        },
      },
      'mission-page': {
        KeyVisual: {
          title: '',
          description: '',
          image: {
            data: null,
          }
        },
        OurMission: {
          text1: '',
          text2: '',
          text3: '',
        },
        ThreeYearsStrategy: {
          title_string1: '',
          title_string2: '',
          subtitle: '',
          motto: '',
          ThreeYearsStrategyItem1: {
            id: null,
            title: '',
            description: ''
          },
          ThreeYearsStrategyItem2: {
            id: null,
            title: '',
            description: ''
          },
          ThreeYearsStrategyItem3: {
            id: null,
            title: '',
            description: ''
          },
          ThreeYearsStrategyItem4: {
            id: null,
            title: '',
            description: ''
          }
        },
        BottomNavigation: {
          previous: '',
          next: ''
        }
      },
      'team-page': {
        KeyVisual: {
          title: '',
          description: '',
          image: {
            data: null,
          }
        },
        Block1: {
          title: ''
        },
        BottomNavigation: {
          previous: '',
          next: ''
        }
      },
      'value-page': {
        TabContent1: {
          item: [
            {
              image: {
                data: null
              }
            },
            {
              image: {
                data: null
              }
            },
            {
              image: {
                data: null
              }
            },
          ]
        },
        TabContent2: {
          item: [
            {
              image: {
                data: null
              }
            },
            {
              image: {
                data: null
              }
            },
            {
              image: {
                data: null
              }
            },
          ]
        }
      },
      'charity-page': {
        HelpEducation: {
          title: '',
          description: ''
        }
      }
    },
  },
  getters: {
    pages: (state) => { return state.pages },
    staticUrl: (state) => { return state.staticUrl },
    isContentLoaded: (state) => { return state.isContentLoaded },
    isCardsLoaded: (state) => { return state.isCardsLoaded },
    activeMerchMobileTab: (state) => { return state.activeMerchMobileTab },
    extraPages: (state) => { return state.extraPages },
    cards: (state) => { return state.cards },
    nav: (state) => { return state.nav },
    version: (state) => { return state.version },
    isVersionSet: (state) => { return state.isVersionSet },
    teams: (state) => { return state.teams },
    currentTeam: (state) => { return state.currentTeam },
  },
  mutations: {
    SET_PAGE_DATA: (state, data) => {
      state.pages[data.page] = data
    },
    SET_CARDS: (state, data) => {
      state.cards = data

      let obj = {}
      data.forEach(item => {
        obj[item.attributes.shortname] = {
          title: item.attributes.title,
          titleMob: item.attributes.title,
          descr: item.attributes.description,
          img: item.attributes.iconNav.data ? item.attributes.iconNav.data.attributes.url : '',
        }
      })
      state.nav = obj
    },
    SET_CARDS_LOADED: (state, val) => {
      state.isCardsLoaded = val
    },
    SET_CONTENT_LOADED: (state, val) => {
      state.isContentLoaded = val
    },
    SET_ACTIVE_MERCH_MOBILE_TAB: (state, val) => {
      state.activeMerchMobileTab = val
    },
    SET_EXTRA_PAGES: (state, val) => {
      state.extraPages = val
    },
    SET_VERSION: (state, val) => {
      state.version = val
      state.isVersionSet = true
    },
    SET_TEAMS: (state, data) => {
      state.teams = data
    },
    SET_CURRENT_TEAM: (state, val) => {
      state.currentTeam = val
    }
  },
  actions: {
    getCards({ commit, state }) {
      axios.get(`${state.apiUrl}/pages?populate=*`)
        .then((r) => {
          const obj = r.data.data
          if (obj) {
            commit('SET_CARDS', obj)
            commit('SET_CARDS_LOADED', true)
          }
        })
    },
    getTeams({ commit, state }) {
      return axios.get(`${state.apiUrl}/teams?populate=*`)
        .then((r) => {
          const obj = r.data.data
          if (obj) {
            commit('SET_TEAMS', obj)
          }
        })
    },
    getPage({ commit, state }, data) {
      axios.get(`${state.apiUrl}/${data.apiPage}${state.version}?populate=${data.search}`)
        .then((r) => {
          const obj = r.data.data
          if (obj) {
            const pageData = obj.attributes
            pageData.page = data.apiPage
            commit('SET_PAGE_DATA', pageData)
            commit('SET_CONTENT_LOADED', true)
          }
        })
    },
    getExtraPage({ commit, state }, data) {
      return axios.get(`${state.apiUrl}/${data.apiPage}?populate=${data.search}`)
              .then((r) => {
                const arr = r.data.data
                if (arr.length > 0) {
                  let content = {}
                  arr.forEach((item) => {
                    content[item.attributes.shortname] = {
                      content: item.attributes.content
                    }

                    const blocks = item.attributes.blocks
                    if (blocks.length > 0) {
                      blocks.forEach(block => {
                        const element = content[item.attributes.shortname]

                        if (block['__component'] == "block.key-visual-block") {
                          element.KeyVisual = block
                        }
                        if (block['__component'] == "block.video-block") {
                          element.Video = block
                        }
                        if (block['__component'] == "block.user-text-block") {
                          if (!element.UserText) {
                            element.UserText = []
                          }
                          element.UserText.push(block)
                        }
                        if (block['__component'] == "block.image-with-text") {
                          if (!element.imageWithText) {
                            element.imageWithText = []
                          }
                          element.imageWithText.push(block)
                        }
                        if (block['__component'] == "block.image-block") {
                          element.imageBlock = block
                        }
                        if (block['__component'] == "block.vertical-cards") {
                          element.verticalCards = block
                        }
                        if (block['__component'] == "navigation.navigation") {
                          element.BottomNavigation = block
                        }

                      })
                    }
                  })

                  commit('SET_EXTRA_PAGES', content)
                  commit('SET_CONTENT_LOADED', true)
                }
              })
    }
  },
  modules: {
  }
})
