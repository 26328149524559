<template>
  <section class="motto">
    <div class="wrapper">
      <div class="motto__img1 img-w mobile-hidden">
        <img v-if="page.MottoBgImageLeft.data"
             :src="$store.getters.staticUrl + page.MottoBgImageLeft.data.attributes.url"
             alt="">
        <svg v-else viewBox="0 0 117 209" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M28.3107 132.382C28.3107 130.048 26.5765 160.538 11.1674 168.514C7.95639 170.177 4.66729 172.179 1 172.179C26.75 170.969 28.6943 195.738 29.091 208C29.091 197.642 30.7716 189.622 37.2508 180.678C40.8797 175.669 46.8926 172.179 53.0885 172.179C57.0286 172.179 54.4285 173.506 51.7589 172.569C46.0266 170.558 41.7755 167.216 37.6027 163.056C26.1561 151.644 30.0274 147.163 28.3107 132.382Z" stroke="white" stroke-width="3" stroke-linecap="round"/>
          <path d="M101.222 137.729C101.222 136.562 100.355 151.806 92.6501 155.795C91.0446 156.626 89.4001 157.627 87.5664 157.627C100.441 157.022 101.414 169.407 101.612 175.538C101.612 170.358 102.452 166.349 105.692 161.877C107.506 159.372 110.513 157.627 113.611 157.627C115.581 157.627 114.281 158.29 112.946 157.822C110.08 156.817 107.954 155.146 105.868 153.066C100.144 147.36 102.08 145.119 101.222 137.729Z" stroke="white" stroke-width="3" stroke-linecap="round"/>
          <path d="M63.4625 2.1779C63.4625 -1.08956 61.0346 41.5959 39.4619 52.7633C34.9665 55.0904 30.3618 57.894 25.2275 57.894C61.2776 56.1998 63.9995 90.8763 64.555 108.043C64.555 93.5414 66.9078 82.3145 75.9786 69.7929C81.0592 62.7795 89.4771 57.894 98.1514 57.894C103.668 57.894 100.027 59.7509 96.29 58.4398C88.2648 55.6247 82.3132 50.946 76.4713 45.1218C60.446 29.1449 65.8659 22.8718 63.4625 2.1779Z" stroke="white" stroke-width="3" stroke-linecap="round"/>
        </svg>
      </div>

      <div class="motto__img2 img-w mobile-hidden">
        <img v-if="page.MottoBgImageRight.data"
             :src="$store.getters.staticUrl + page.MottoBgImageRight.data.attributes.url"
             alt="">
        <svg v-else viewBox="0 0 168 148" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M146.857 52.469C139.625 54.8654 131.758 56.7479 124.88 60.0736C123.87 60.5622 122.126 60.7424 122.687 61.9818C124.057 65.0098 124.961 68.2423 125.756 71.453C127.732 79.432 129.628 87.4109 131.67 95.3739C132.597 98.9908 132.48 102.917 133.703 106.441" stroke="white" stroke-width="3" stroke-linecap="round"/>
          <path d="M149.966 52.0674C149.257 64.1244 153.03 75.4375 154.067 87.3298C154.358 90.6602 154.254 94.1987 154.813 97.5001C155.15 99.4938 156.397 101.354 156.559 103.365C156.816 106.562 155.351 107.632 152.574 108.552C146.524 110.556 139.317 110.829 132.961 111.829" stroke="white" stroke-width="3" stroke-linecap="round"/>
          <path d="M132.36 65.0312C137.811 65.0573 140.383 65.6389 142.219 70.932C143.324 74.1144 142.924 79.2052 137.603 77.1169C134.923 76.0655 132.093 73.6406 131.146 70.9124C130.815 69.9587 129.946 69.5091 129.659 68.5043C129.232 67.0085 132.139 66.1669 133.144 65.4159" stroke="white" stroke-width="3" stroke-linecap="round"/>
          <path d="M120.713 69.7398C116.809 71.6571 113.082 73.1821 108.702 73.7188C105.431 74.1196 99.0868 74.338 96.3987 71.9536C89.7784 66.0814 85.2029 56.2571 81.9812 48.3491C80.2444 44.0857 78.4462 39.9361 76.9343 35.5798C75.7573 32.1885 74.9557 28.0201 72.6691 25.1674C69.6155 21.3577 63.2104 22.9222 59.9789 26.1463C54.6581 31.4549 59.5325 42.7238 61.2753 48.7583C62.3688 52.5444 62.6708 56.4294 63.965 60.1585C64.7414 62.3957 65.6206 64.6598 65.3862 67.0695C65.115 69.8581 67.5621 72.6934 67.0396 75.5879C66.5279 78.4223 58.1059 76.7258 55.7299 76.7903C47.39 77.0165 38.2511 79.1192 30.3653 81.6096C26.5457 82.8159 18.5703 84.9216 18.5059 90.1969C18.3665 101.606 43.2685 96.505 49.6714 96.3562C50.9289 96.327 60.1844 95.17 54.7454 95.7503C50.6498 96.1872 47.1214 96.6993 43.2176 97.9925C38.4665 99.5664 32.7868 100.319 30.3896 105.145C27.0524 111.863 43.642 108.725 45.9845 108.364C51.2861 107.546 56.8657 106.721 62.0417 105.302C64.1674 104.719 67.654 104.088 63.4111 105.493C54.4923 108.448 44.9492 109.029 35.9891 111.997C33.5331 112.811 24.6211 114.993 25.832 119.232C27.6983 125.766 44.4972 120.582 48.8932 119.978C54.7534 119.172 72.3568 117.093 66.4416 117.354C57.7496 117.737 49.9421 119.983 41.5456 122.376C37.6969 123.473 30.5647 123.483 32.5683 129.256C33.7307 132.605 33.7085 133.04 37.3415 133.623C40.1762 134.079 43.4281 133.823 46.4366 133.549C49.9574 133.229 54.2452 133.547 57.6721 132.55C64.3292 130.614 70.8424 129.477 77.5185 127.266C80.9225 126.138 84.4864 124.463 88.0149 123.824C90.154 123.437 90.9957 123.47 92.7189 121.943C94.1994 120.631 96.3255 119.251 97.5531 117.726C99.282 115.578 99.508 113.442 102.357 112.587C106.309 111.401 110.746 112.059 114.717 111.072C120.662 109.595 126.848 108.343 132.777 106.379" stroke="white" stroke-width="3" stroke-linecap="round"/>
        </svg>
      </div>

      <p>
        <vue-simple-markdown
          :source="$options.filters.typograf(page.Motto)">
        </vue-simple-markdown>
      </p>
    </div>
  </section>
</template>

<script>
  export default {
    name: "PageMainBottom",
    computed: {
      page() {
        return this.$store.getters.pages['main-page']
      }
    },
  }
</script>

<style scoped lang="scss">
  @import './bottom.scss';
</style>
