<template>
  <div id="app">
    <div v-if="isReady">
      <PageHeader />
      <router-view/>
      <PageFooter />
    </div>
    <CheckTeamModal />
  </div>
</template>

<style lang="scss">
#app {
  font-family: 'VK Sans Display';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #fff;
  font-weight: 400;
}
.home {
  min-height: calc(100vh - 15rem);
}
</style>
<script>
  import PageHeader from "./components/PageHeader/PageHeader";
  import PageFooter from "./components/PageFooter/PageFooter";
  import CheckTeamModal from "./components/CheckTeamModal/CheckTeamModal";
  export default {
    components: {CheckTeamModal, PageFooter, PageHeader},
    data() {
      return {
        apiKey: 'bafb44ee681040f3fc3b26f66104e9d3ba915751cbab9565b5a84c0dc5ca035398d930c7932350b7f428ff343ae38edf155615055a0ff4219bd7248ea3978ae6c3e4105f8c8922d06f1e9e0d06c63ced8b9261345909417174bd89b362574c415925b8909b6467f5cc8290e2004be6bee2a46677d95169d58790f92b933e538a'
      }
    },
    computed: {
      isReady() {
        return this.$store.getters.isVersionSet
      },
    },

    async mounted() {
      await this.$store.dispatch('getTeams')
      await this.$modal.show('version')
      this.$store.dispatch('getCards')
    },
  }
</script>
