<template>
  <modal name="version" height="auto">
    <div class="modal__content">
      <h4>Выбери роль:</h4>

      <div class="modal__buttons">
        <div v-for="(team, index) in teams"
                :key="team.id"
                @click="setVersion(index, team.attributes.teamname)"
                class="button">
          {{ team.attributes.teamname }}
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  export default {
    name: "CheckTeamModal",
    computed: {
      teams() {
        return this.$store.getters.teams
      }
    },
    methods: {
      setVersion(index, team) {
        let v = ''
        if (index === 1) v = '-v2'
        if (index === 2) v = '-v3'
        this.$store.commit('SET_VERSION', v)
        this.$store.commit('SET_CURRENT_TEAM', team)
        this.$modal.hide('version')
        this.$store.commit('SET_CONTENT_LOADED', false)
      },
    }
  }
</script>

<style scoped lang="scss">

</style>
